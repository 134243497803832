.whats-app-header{
  display: flex !important;
  align-content: center;
  align-items: center;
  padding: 12px 0px;
  margin-top: 9px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}
.whats-app-header p {
  color: #fff !important;
  margin: 0px;
  margin-left: 10px;
}
.whats-app-header img {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  /* padding: px; */
  background-color: #fff;
}
.call-action-area {
  border: 1px solid #e5e1e1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 12px;
}
.whatsapp-review {
  height: 700px;
  margin: 0px auto;
  display: block;
}

.whatsapp-bg{
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100%;
  margin: 0 auto;
  max-width: 290px;
  height: 584px;
  padding: 13px 0px 0px 30px;
}
.body-message {
  background: #fff;
  padding: 10px;
  width: 90%;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 18px;
  min-height: 52px;
  white-space: pre-line;
  overflow-y: scroll;
  max-height: 300px;
}

.doc-type {
  min-height: 100px;
  border: dotted 2px gray;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

.button-text {
  background: #fff;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  padding: 5px;
  width: 90%;
}
.button-text p{
  margin: 0px;
  font-size: 13px !important;
  color: #00a7ff !important;
}

.body-message p {
  font-size: 12px !important;
  color: gray !important;
}
.body-message strong {
  font-size: 14px !important;
  color: #000 !important;
}
.whatsapp ul{
  list-style: none;
  padding: 0px;
  margin-top: -17px;
}

.whatsapp li {
  font-size: 13px;
  border-bottom: 1px solid #f1e7e7;
  padding: 5px 0px;
  color: #065f54;
  font-weight: 600;
}

.whatsapp strong {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  text-transform: capitalize;
 }

 .whatsapp-box {
  width: 100%;
  border: 1px solid #cfc9c9;
  padding: 10px;
  margin-bottom: 18px;
  border-radius: 5px;
  padding-bottom: 60px;
}
.whatapp-app .form-control {
  color: #000;
  font-weight: 500;
}

.whatsapp-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.whatsapp-box h4{
  margin: 0px;
  font-size: 14px;
}
.whatsapp-box button {
  padding: 5px 10px;
  font-size: 10px;
}
.whatsapp-options p{
  margin: 0px;
  font-size: 13px !important;
  color: gray!important;
}

.choose-options {
  font-size: 13px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin: 10px 0px;
}

.chatBorderBlock {
  background: #fff;
  border: 1px solid #d9dbe4;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
}

.ChatLive_card {
    background: #fff;
    border: 1px solid #d9dbe4;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    width: 240px;
}

.liveChatList li a {
  color: #000;
  display: block;
  padding: 7px 7px;
}
.liveChatList li a.active {
  background: rgba(3, 168, 78, .1);
  border-radius: 5px;
  color: #03a84e;
}
.liveChatList li a:hover {
  color: #03a84e;
}
.TitleBox {
  border-bottom: 1px solid #d9dbe4;
  margin-bottom: 15px;
}
.wFull {
  width:100%;
}
.iconWH {
  font-size: 18px;
  vertical-align: center;
}
.MinH {
  min-height: 360px;
}
.MinHalf{
  min-height: 168px;
}
.yellowish {
  background: rgba(255, 210, 63, .2);
}
.orangeish {
  background: rgba(255, 132, 54, .2);
}
.greenish {
  background: rgba(37, 211, 102, .2);
}
.blueish {
  background: rgba(26, 115, 233, .2);
}
.voiletish {
  background: rgba(58, 52, 98, .2);
}
.pinkColor svg {
  color: rgb(209, 165, 19);
}
.iconCircluate {
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 17px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.iconCircluate svg{
  color: #000;
}
.MinHx {
  min-height: 260px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
}

::-webkit-scrollbar-corner {
  background: 0 0
}
